@import '../../variables';

.AlphabeticNavigation {
  display: flex;
  justify-content: center;
  color: $primary-text;
  border-radius: 5px;
  margin-bottom: 25px;
  background-color: $black;
  font-size: 90%;

  &.MobileScroll {
    .Letters {
      width: 70%;
      overflow: hidden;

      .Active {
        opacity: 0.6;
      }
    }
  }

  &.PartialAlphabet {
    width: 220px;
  }

  .Letters {
    display: flex;
    align-items: center;

    .Letter {
      margin: 0 5px;
      cursor: pointer;
      padding: 5px 0;
    }

    .AlphabeticNavigation-separator {
      border-right: 2px solid $white;
      opacity: 0.5;
      height: 50%;
      margin-top: 25%;
    }
  }

  .EndMarker {
    margin: 2px 15px;
    font-size: 28px;
  }
}
