@import '../../variables';

.UserActions {
  display: none;
  cursor: pointer;
  color: $dark-blue;
  font-size: 110%;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;

  @media (min-width: $breakpoint-tablet) {
    display: flex; 
  }

  .UserAction:hover {
    color: $brown;
  }

  .LoginWrapper {
    display: flex;
    align-items: center;

    &:hover {
      .Arrow {
        border-top: unset;
        border-bottom: 0.3em solid $brown;
      }
    }

    .Arrow {
      border-top: 0.4em solid $brown;
      margin-left: 5px;
    }
  }
}
