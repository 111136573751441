@import '../../variables';

.MovieOverlay {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  z-index: 1;
  background: linear-gradient(to right, $dark-black, transparent, transparent);

  &::before {
    position: absolute;
    content: '';
    display: block;
    left: 0;
    width: 40%;
    height: 100%;
    z-index: -1;
  }

  .TextWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 20px 20px 100px;
    padding: 30px 80px 30px 20px;
    width: 100%;
    max-width: 500px;
    font-size: 20px;
    font-family: $font-family-metropolis;

    .TopText {
      font-size: 1.2rem;
      font-weight: 400;
      color: $gray;
    }

    .MiddleText {
      margin-top: 10px;
      margin-bottom: 14px;
      font-size: 2.3rem;
      font-weight: 900;
      line-height: 0.95;
    }

    .BottomText {
      font-size: 16px;
      font-size: 1rem;
      text-transform: capitalize;
      font-weight: 600;
      color: $secondary;
    }

    .WatchNowBtn {
      background-color: $brown;
      border-radius: 10px;
      border: none;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 10px 40px;
      margin-top: 30px;
      cursor: pointer;
      font-family: $font-family-oswald;
      font-size: 20px;
      font-weight: bold;
      color: $dark-black;
      max-width: 280px;

      &:hover {
        color: $white;
      }
    }

    @media (min-width: 1366px) {
      .TopText {
        font-size: 1.3rem;
      }

      .MiddleText {
        margin-top: 14px;
        margin-bottom: 16px;
      }

      .BottomText {
        font-size: 18px;
      }

      .WatchNowBtn {
        margin-top: 50px;
        font-size: 30px;
      }
    }

    @media (min-width: 1500px) {
      font-size: 30px;

      > .BottomText {
        font-size: 60px;
      }
    }

    @media (min-width: 1800px) {
      font-size: 40px;

      > .BottomText {
        font-size: 70px;
      }
    }

    @media (min-width: 2000px) {
      font-size: 50px;

      > .BottomText {
        font-size: 80px;
      }
    }
  }

  .Fill {
    flex: 1;
    min-width: 0;
    background-color: rgba(255, 255, 255, 0.1);
  }
}
